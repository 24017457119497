import './LoginToken.css'
import { useState } from 'react'
import { login } from '../../global/API'
import { storeLoginJWT } from './../../global/Storage'

const LoginToken = () => {
    const [user, setUser] = useState({
        id: '',
        name: '',
        password: '',
        role: null
    })

    const handleChange = (e) => {
        const { value, name } = e.target

        setUser({ ...user, [name]: value })
    }

    const handleLogin = async () => {
        const loginToken = await login(user)
        .catch(err => {
            throw err
        })

        if (loginToken !== undefined) {
            storeLoginJWT(loginToken);
            window.location = "/"
        }
    }

    return (
        <div className='layout'> 
            <div className='login'>
                <h2>Přihlášení</h2>
                <div className='login-form'>
                    <label>Uživatelské jméno</label>
                    <input name='name' type='text' onChange={handleChange} />
                    <label>Heslo</label>
                    <input name='password' type='password' onChange={handleChange} />
                </div>
                <button onClick={handleLogin}>Přihlásit</button>
            </div>

        </div>
    )
}
export default LoginToken