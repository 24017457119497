// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.boiler_rooms {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 5vh;
    width: 70vw;
}

.boiler_rooms_header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #289cef;
    padding: 1em 2em;
    margin-bottom: 4vh;

    border-radius: 12px;

}

.boiler_rooms_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: #4E7B9B;
    color:  white;

    width: 70%;
    padding: 2%;
    margin: 2% 0;

    border-radius: 12px;
    cursor: pointer;
    text-align: center;
}

.boiler_rooms_item p {
    font-weight: bold;
    font-size: 1.1em;
}

.boiler_rooms_item h3 {
    font-size: 1.2em;
}

@media only screen and (width < 600px) {
    .boiler_rooms_item {
       width: 96%;
    } 
}`, "",{"version":3,"sources":["webpack://./src/pages/Boilerrooms.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;;IAEnB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;;IAElB,mBAAmB;;AAEvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;;IAEvB,yBAAyB;IACzB,aAAa;;IAEb,UAAU;IACV,WAAW;IACX,YAAY;;IAEZ,mBAAmB;IACnB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;OACG,UAAU;IACb;AACJ","sourcesContent":[".boiler_rooms {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    margin-top: 5vh;\n    width: 70vw;\n}\n\n.boiler_rooms_header {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    background-color: #289cef;\n    padding: 1em 2em;\n    margin-bottom: 4vh;\n\n    border-radius: 12px;\n\n}\n\n.boiler_rooms_item {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n\n    background-color: #4E7B9B;\n    color:  white;\n\n    width: 70%;\n    padding: 2%;\n    margin: 2% 0;\n\n    border-radius: 12px;\n    cursor: pointer;\n    text-align: center;\n}\n\n.boiler_rooms_item p {\n    font-weight: bold;\n    font-size: 1.1em;\n}\n\n.boiler_rooms_item h3 {\n    font-size: 1.2em;\n}\n\n@media only screen and (width < 600px) {\n    .boiler_rooms_item {\n       width: 96%;\n    } \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
