const loginJWT = "LOGIN_JWT"

export const storeLoginJWT = (jwt) => {
    localStorage.setItem(loginJWT, jwt)
}

export const loadLoginJWT = () => {
    return localStorage.getItem(loginJWT)
}

export const deleteLoginJWT = () => {
    localStorage.removeItem(loginJWT)
}