    import React, { useEffect, useState } from 'react';
import './Detail.css'
import { useNavigate, useParams } from 'react-router-dom';
import { ENDPOINTS, load, store } from '../../global/API';
import { defBoilerroom } from '../../global/Constants';


const BoilerroomDetail = () => {    
       
    const { id } = useParams()
    const navigate = useNavigate()
    
    const [title, setTitle] = useState('')
    const [data, setData] = useState({...defBoilerroom});
    
    useEffect(() => {
        if (id !== undefined) {
            load(ENDPOINTS.BOILER_ROOM, [id])
            .then(data => {
                const d = data.pop()
                if (d !== undefined) {                
                    setData(d)
                }          
            })
            .catch(err => console.log(err))
        }
    }, [])

    
    useEffect(() => {
        if (id === undefined) {
            setTitle('Nová kotelna')
        } else {
            setTitle(`Kotelna: ${id}`)
        }
    }, [data])

    const storeDate = () => {

        store(ENDPOINTS.BOILER_ROOM, [data])
            .then(data => { navigate("/management/boiler_rooms/") })
            .catch(err => console.log(err))
    }

    const handleChange = (e) => {
        const {name, value} = e.target

        setData({...data, [name]: value})
    }

    return (
        <div className='detail'>
            <h2>{title}</h2>
            <div className='detail-body'>
                <label>Název</label>
                <input type='text' name='name' value={data.name} onChange={handleChange} />
            </div>
            <div className='detail-submit'>
                <a href="/management/boiler_rooms/">Zahodit změny</a>
                <button onClick={() => storeDate()}>
                    {id === undefined ? 'Vytvořit' : 'Potvrdit změny'}
                </button>
            </div>
        </div>
    )
}

export default BoilerroomDetail