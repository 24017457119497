import { useEffect, useState } from 'react'
import './Boilerroom.css'
import { useNavigate, useParams } from 'react-router-dom'
import { MONTHS, defBoilerroom, defRecord, findFirstDayOfMonth } from '../global/Constants'
import { ENDPOINTS, load, remove, storeRecords } from '../global/API'

const Boilerroom = () => {

    const { id } = useParams()
    const navigete = useNavigate()

    const [date, setDate] = useState(findFirstDayOfMonth())
    const [selectedDate, setSelectedDate] = useState(new Date())

    const [boilerroom, setBoilerroom] = useState({...defBoilerroom})
    const [records, setRecords] = useState([])
    const [selectedRecord, setSelectedRecord] = useState({...defRecord})

    const [weeks, setWeeks] = useState(<></>)

    useEffect(() => {
        if (id !== undefined) {
            load(ENDPOINTS.BOILER_ROOM, [id])
            .then(data => {
                const d = data.pop()
                if (d !== undefined) {                
                    setBoilerroom(d)
                }          
            })
            .catch(err => console.log(err))
        } else 
            navigete("/")
    }, [])

    useEffect(() => {
        if (boilerroom.id !== null) {
            load(ENDPOINTS.BOILER_ROOM_GET_RECORDS, [[boilerroom.id, date.getFullYear() + '_' + date.getMonth() ]])
            .then(data => setRecords(data))
            .catch(err => console.log(err))
        }
    }, [boilerroom])

    useEffect(() => {
        const sr = records.find(r => 
            r.recorded[0] === selectedDate.getFullYear() &&
            r.recorded[1] === selectedDate.getMonth() + 1 &&
            r.recorded[2] === selectedDate.getDate()
        )

        if (sr === undefined) {
            setSelectedRecord({...defRecord, "recorded": selectedDate})
        } else 
            setSelectedRecord({...sr})
    }, [selectedDate, records])

    useEffect(() => {
        const month = date.getMonth()
        const tempDate = findFirstDayOfMonth(date)

        const weeks = []

        console.log(tempDate, tempDate.getDay());
        

        while (month === tempDate.getMonth()) {
            weeks.push(generateWeek(tempDate, month))
        }

        setWeeks(weeks)
    }, [boilerroom, date])

    useEffect(() => {
        //console.log(selectedRecord);
    }, [selectedRecord])

    const generateWeek = (date, thisMonth) => {
      
        while (date.getDay() !== 1) {
            date.setDate(date.getDate() - 1)
        }

        const tds = []
        for (let i = 0; i < 7; i++) {
            const newDate = new Date(date)

            const htmlclass = newDate.getMonth() === thisMonth ? "" :  "other_month"

            tds.push(
                <td key={date.getDate()} className={htmlclass} onClick={() => {
                    if (newDate.getMonth() === thisMonth) 
                        setSelectedDate(newDate)
                }}>{date.getDate()}</td>
            )

            date.setDate(date.getDate() + 1)
        }

        return (
            <tr key={`${date.getDate()} ${date.getMonth()}`}>
                { tds }
            </tr>
        )
    } 

    const handleChange = (e) => {
        const { name, value } = e.target

        setSelectedRecord({...selectedRecord, [name]: parseFloat(value)})
    }

    return (
        <div className='boilerroom'>
            <h2>Kotelna: {boilerroom.name}</h2>
            <table>
                <thead>
                    <tr>
                        <th colSpan={2} onClick={() => {
                            date.setMonth(date.getMonth() - 1)
                            setDate(new Date(date))
                        }}>Předchozí</th>
                        <th colSpan={3}>{MONTHS[date.getMonth()]} {date.getFullYear()}</th>
                        <th colSpan={2} onClick={() => {
                            date.setMonth(date.getMonth() + 1)
                            setDate(new Date(date))
                        }}>Následující</th>
                    </tr>
                    <tr>
                        <th>Po</th>
                        <th>Út</th>
                        <th>St</th>
                        <th>Čt</th>
                        <th>Pá</th>
                        <th>So</th>
                        <th>Ne</th>                    
                    </tr>
                </thead>
                <tbody>
                    { weeks }
                </tbody>
            </table>
            <div>
                <h3>Záznam ze dne: {selectedDate.getDate()}.{selectedDate.getMonth()+1}</h3>
                <div className='boilerroom_content'>
                    <label>Stav plynoměru</label>
                    <input type='number' name='stateOfGasometer' onChange={handleChange} value={selectedRecord.stateOfGasometer} min={0} />
                </div>
                <div className='boilerroom_content'>
                    <button onClick={() => {
                        sessionStorage.setItem("selectedRecord", JSON.stringify(selectedRecord))
                        navigete(`/generated_preview/${id}`)
                    }}>Dopočítat</button>
                    <button onClick={() => {
                        storeRecords(id, [selectedRecord])
                        .then(() => setBoilerroom({...boilerroom}))
                    }}>{selectedRecord.id !== null ? "Upravit" : "Zadat"}</button>
                    <button onClick={() => {
                        remove(ENDPOINTS.BOILER_ROOM_DELETE_RECORD, [{...selectedRecord, ["id"]: boilerroom.id + "/" + selectedRecord.recorded.map(e => e.toString().padStart(2, "0")).join("-")}])
                        .then(res => {
                            const newRecords = records.filter(r => !( 
                                r.recorded[0] === selectedDate.getFullYear() &&
                                r.recorded[1] === selectedDate.getMonth() + 1 &&
                                r.recorded[2] === selectedDate.getDate())
                            )

                            setRecords([...newRecords])
                            setSelectedRecord({...defRecord})
                            setBoilerroom({...boilerroom})
                        })
                    }}>Odstranit záznam</button>
                </div>
            </div>
        </div>
    )
}

export default Boilerroom