import './Render.css'

const RoleRender = ({record}) => {

    console.log(record.permissions);

    return (
        <div className='render'>
            <p><b>Název:</b> {record.name}</p>
            <p><b>Oprávnění: </b> 
                {Object.values(record.permissions).length}
            </p>
        </div>
    )
}

export default RoleRender