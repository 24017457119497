import './Render.css'

const BoilerroomRender = ({record}) => {
    return (
        <div className='render'>
            <p><b>Název:</b> {record.name}</p>
        </div>
    )
}

export default BoilerroomRender