import React, { useEffect, useState } from 'react';
import './Detail.css'
import { useNavigate, useParams } from 'react-router-dom';
import { ENDPOINTS, load, store } from '../../global/API';
import { defRole } from '../../global/Constants';


const RoleDetail = () => {    
       
    const { id } = useParams()
    const navigate = useNavigate()
    
    const [title, setTitle] = useState('')
    const [data, setData] = useState({...defRole});

    const [permissions, setPermissions] = useState([])
    
    useEffect(() => {
        if (id !== undefined) {
            load(ENDPOINTS.ROLE, [id])
            .then(data => {
                const d = data.pop()
                if (d !== undefined) {                
                    setData(d)
                }          
            })
            .catch(err => console.log(err))
        }
    }, [])

    useEffect(() => {
        load(ENDPOINTS.PERMISSION)
        .then(data => setPermissions(data))
        .catch(err => console.log(err))
    }, [])
    
    useEffect(() => {
        if (id === undefined) {
            setTitle('Nové role')
        } else {
            setTitle(`Role: ${id}`)
        }
    }, [data])

    const storeDate = () => {

        store(ENDPOINTS.ROLE, [data])
            .then(data => { navigate("/management/roles/") })
            .catch(err => console.log(err))
    }

    const handleChange = (e) => {
        const {name, value} = e.target

        setData({...data, [name]: value})
    }

    return (
        <div className='detail'>
            <h2>{title}</h2>
            <div className='detail-body'>
                <label>Název</label>
                <input type='text' name='name' value={data.name} onChange={handleChange} />
                <label>Oprávnění</label>
                <div className='role-datail-permissions'>
                    {
                        permissions.map((p,i) => (
                            <div key={i} className='role-datail-permissions-permission'>
                                <p>{p.permission}</p>
                                <input type='checkbox' 
                                checked={data.permissions[p.id] !== undefined} 
                                onChange={(e) => {
                                    const { checked } = e.target
                                    if (checked) {
                                        data.permissions[p.id] = p
                                    } else {
                                        delete data.permissions[p.id]
                                    }

                                    setData({...data})
                                }} />
                            </div>
                        ))
                    }
                </div>                
            </div>
            <div className='detail-submit'>
                <a href="/management/roles/">Zahodit změny</a>
                <button onClick={() => storeDate()}>
                    {id === undefined ? 'Vytvořit' : 'Potvrdit změny'}
                </button>
            </div>
        </div>
    )
}

export default RoleDetail