import { useEffect, useState } from 'react'
import './Spreadsheet.css'
import { useNavigate } from 'react-router-dom'
import { load, remove } from '../../global/API'

const Spreadsheet = ({pathToApi, editPath, RecordRender, spreadsheetHeader}) => {

    const navigate = useNavigate()    
    const [data, setData] = useState([])
    
    useEffect(() => {
        load(pathToApi)
            .then(data =>  setData(data))
            .catch(err => console.log(err))
    }, [])

    const removeData = (record) => {
        remove(pathToApi, [record])
            .then(res => setData([...data.filter(d => d.id !== record.id)]))
            .catch(err => console.log(err))
    }

    return (
        <div className='sp'>
            <div className="sp-header">
                <p className='sp-header-title'>{spreadsheetHeader}</p>
                <a href={`${editPath}new`}><b>Nový</b></a>
            </div>
            <div className="sp-body">
                { data.map((d,i) => {      
                    return <div key={i} className='sp-body-record' onClick={()=>navigate(editPath + d.id)}>
                            <div className='sp-body-record-conten'>
                                { <RecordRender record={d} /> }
                            </div>
                            <input type='button' value='Odebrat' onClick={() => removeData(d)} />
                        </div>
                    }) }
            </div>
        </div>
    )
}

export default Spreadsheet