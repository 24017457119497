import './Render.css'

const PermissionRender = ({record}) => {
    return (
        <div className='render'>
            <p><b>Název:</b> {record.permission}</p>
        </div>
    )
}

export default PermissionRender