    import React, { useEffect, useState } from 'react';
import './Detail.css'
import { useNavigate, useParams } from 'react-router-dom';
import { ENDPOINTS, load, store } from '../../global/API';
import { defRole, defUser } from '../../global/Constants';


const UserDetail = () => {    
       
    const { id } = useParams()
    const navigate = useNavigate()
    
    const [title, setTitle] = useState('')
    const [data, setData] = useState({...defUser});
    const [roles, setRoles] = useState([])
    
    useEffect(() => {
        if (id !== undefined) {
            load(ENDPOINTS.USER, [id])
            .then(data => {
                const d = data.pop()       
                if (d !== undefined) {   
                    setData(d)
                }          
            })
            .catch(err => console.log(id))
        }
    }, [])

    useEffect(() => {
        load(ENDPOINTS.ROLE)
        .then(data => {
            data.unshift(defRole)
            setRoles(data)
        })
        .catch(err => console.log(err))
    }, [])
    
    useEffect(() => {
        if (id === undefined) {
            setTitle('Nový uživatel')
        } else {
            setTitle(`Uživatel: ${id}`)
        }
    }, [data])

    const storeDate = () => {

        store(ENDPOINTS.USER, [data])
            .then(data => { navigate("/management/users/") })
            .catch(err => console.log(err))
    }

    const handleChange = (e) => {
        const {name, value} = e.target

        setData({...data, [name]: value})
    }

    const handleSelect = (e) => {
        const { name, value } = e.target

        const role = roles.find(r => r.id === value)

        setData({... data, [name] : role})
    }

    return (
        <div className='detail'>
            <h2>{title}</h2>
            <div className='detail-body'>
                <label>Uživatelské jméno</label>
                <input type='text' name='name' value={data.name} onChange={handleChange} />
                <label>Heslo</label>
                <input type='password' name='password' defaultValue={''} onChange={handleChange} />
                <label>Role</label>
                <select name='role' onSelect={handleSelect} >
                    {roles.map((r,i) => 
                        <option key={i} value={r.id} selected={r.id === data.role.id}>{r.name}</option>
                    )}
                </select>
            </div>
            <div className='detail-submit'>
                <a href="/management/users/">Zahodit změny</a>
                <button onClick={() => storeDate()}>
                    {id === undefined ? 'Vytvořit' : 'Potvrdit změny'}
                </button>
            </div>
        </div>
    )
}

export default UserDetail