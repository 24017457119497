import MainLayout from "./layouts/MainLayout"
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import Err from './errs/Err'
import { loadLoginJWT } from './global/Storage'
import LoginToken from "./pages/auth/LoginToken";
import Boilerrooms from "./pages/Boilerrooms";
import Management from "./pages/management/Management";
import Spreadsheet from "./pages/management/Spreadsheet";
import { ENDPOINTS } from "./global/API";
import BoilerroomRender from "./components/renders/BoilerroomRender";
import UserRender from "./components/renders/UserRender";
import RoleRender from "./components/renders/RoleRender";
import PermissionRender from "./components/renders/PermissionRender";
import PermissionDetail from "./pages/management/PermissionDetail";
import RoleDetail from "./pages/management/RoleDetail";
import UserDetail from "./pages/management/UserDetail";
import BoilerroomDetail from "./pages/management/BoilerroomDetail";
import Boilerroom from "./pages/Boilerroom";
import GeneratedPreview from "./pages/GeneratedPreview";

const App = () => {

    const login = loadLoginJWT() == null ? (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<LoginToken />} />
            </Routes>
        </BrowserRouter> ) : (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<MainLayout />} >
                    {/** stránka zobrazující kotalny */}
                    <Route index element={ <Boilerrooms /> } />
                    {/** stránka zobrazující kotelnu */}
                    <Route path='/boiler_room/:id' element={ <Boilerroom /> }/>
                    {/** stránka zobrazující náhled na nově vygenerovaná data // TODO */}
                    <Route path='/generated_preview/:id' element={ <GeneratedPreview /> }/>
                    {/** stránky pro edit dat */}
                    <Route path='/management/' element={ <Management /> }/>
                    <Route path='/management/boiler_rooms/' element={ 
                        <Spreadsheet 
                            pathToApi={ENDPOINTS.BOILER_ROOM} 
                            editPath={"/management/boiler_rooms/"} 
                            RecordRender={BoilerroomRender} 
                            spreadsheetHeader={"Kotelny"} /> }/>
                    <Route path='/management/boiler_rooms/:id' element={ <BoilerroomDetail /> }/>
                    <Route path='/management/boiler_rooms/new' element={ <BoilerroomDetail /> }/>
                    <Route path='/management/users/' element={ 
                        <Spreadsheet 
                            pathToApi={ENDPOINTS.USER} 
                            editPath={"/management/users/"} 
                            RecordRender={UserRender} 
                            spreadsheetHeader={"Uživatelé"} /> }/>
                    <Route path='/management/users/:id' element={ <UserDetail /> }/>
                    <Route path='/management/users/new' element={ <UserDetail /> }/>
                    <Route path='/management/roles/' element={ 
                        <Spreadsheet 
                            pathToApi={ENDPOINTS.ROLE} 
                            editPath={"/management/roles/"} 
                            RecordRender={RoleRender} 
                            spreadsheetHeader={"Role"} /> }/>
                    <Route path='/management/roles/:id' element={ 
                        <RoleDetail /> }/>
                    <Route path='/management/roles/new' element={ 
                        <RoleDetail /> }/>
                    <Route path='/management/permissions/' element={ 
                        <Spreadsheet 
                            pathToApi={ENDPOINTS.PERMISSION} 
                            editPath={"/management/permissions/"} 
                            RecordRender={PermissionRender} 
                            spreadsheetHeader={"Oprávnění"} /> }/>
                    <Route path='/management/permissions/:id' element={ 
                        <PermissionDetail /> }/>
                    <Route path='/management/permissions/new' element={ 
                        <PermissionDetail /> }/>
                    <Route path='*' element={ <Err />}/>
                </Route>
            </Routes>
        </BrowserRouter>
    )

    return login
}

export default App