export const emailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
export const pwRegex = new RegExp(/^.*(?=.{12,}).*$/)

export const defPermission = {
    id: null,
    permission: ""
}
export const defRole = {
    id: null,
    name: '',
    permissions: {}
}
export const defUser = {
    id: null,
    name: '',
    password: '',
    role: {... defRole}
}
export const defRecord = {
    id: null,
    recorded: new Date(),
    stateOfGasometer: -1
}
export const defBoilerroom = {
    id: null,
    name: '',
    records: {},
    lastRecord: {... defRecord}
}

export const MONTHS = ["Leden", "Únor", "Březen", "Duben", "Květen", "Červen", "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"]

export const findFirstDayOfMonth = (date = new Date()) => {
    const tmpDate = new Date(date)
    tmpDate.setDate(1);
    return tmpDate;
} 

export const countGasDif = (lastOne, arr) => {

    let last = lastOne;
    let gasDif = 0;

    for (let i = 0; i < arr.length; i++) {       
        gasDif += arr[i]?.stateOfGasometer - last?.stateOfGasometer 
        last = arr[i]
    }

    return gasDif
}

export const countConsumption = (actualStateOfGasometer, lastStateOfGasometer) => {

    let consumption = actualStateOfGasometer - lastStateOfGasometer

    consumption = consumption * 10
    consumption = Math.round(consumption)
    consumption = consumption / 10

    return consumption
}