// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.managemant {
    display: flex;
    flex-wrap: wrap;
    
    justify-content: center;
    align-items: center;
    
    height: 80vh;
}

.managemant div {
    font-size: 3em;
    font-weight: bold;
    margin: .5em 5%;
    width: 40%;
    padding: .5em 0;
    background-color: #4E7B9B;
    color: white;
    text-align: center;
    border-radius: 12px;
    cursor: pointer;
}

@media only screen and (width < 600px) {
    .managemant div {
        font-size: 1.7em;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/management/Management.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;;IAEf,uBAAuB;IACvB,mBAAmB;;IAEnB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,eAAe;IACf,UAAU;IACV,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".managemant {\n    display: flex;\n    flex-wrap: wrap;\n    \n    justify-content: center;\n    align-items: center;\n    \n    height: 80vh;\n}\n\n.managemant div {\n    font-size: 3em;\n    font-weight: bold;\n    margin: .5em 5%;\n    width: 40%;\n    padding: .5em 0;\n    background-color: #4E7B9B;\n    color: white;\n    text-align: center;\n    border-radius: 12px;\n    cursor: pointer;\n}\n\n@media only screen and (width < 600px) {\n    .managemant div {\n        font-size: 1.7em;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
