// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}
.login {

    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 5vh 0;
    width: 30vw;

    background-color: #289CEF;
    color: white;
    font-weight: bold;

    border-radius: 12px;

    padding: 4vh 0;

}
.login h2 {
    font-size: 2em;
    margin-bottom: 3vh;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 1.4em;
}
.login-form > * {
    margin-bottom: 1vh;
}

.login-form input {
    font-size: 1em;
    padding: .2vh;
}

.login button {
    margin-top: 2vh;
    font-size: 1.2em;
    color: white;
    background-color: #4E7B9B;
    cursor: pointer;

    padding: 1vh 1vw;
}

@media only screen and (width < 1000px) {
    .login {
        width: fit-content;
        padding: 4vh 2vw;
    }

    .login button {
        font-size: 1.4em;
        padding: 2vh 6vw;
    }
}

`, "",{"version":3,"sources":["webpack://./src/pages/auth/LoginToken.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,aAAa;AACjB;AACA;;IAEI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;;IAEnB,aAAa;IACb,WAAW;;IAEX,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;;IAEjB,mBAAmB;;IAEnB,cAAc;;AAElB;AACA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;;IAEnB,gBAAgB;AACpB;AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,yBAAyB;IACzB,eAAe;;IAEf,gBAAgB;AACpB;;AAEA;IACI;QACI,kBAAkB;QAClB,gBAAgB;IACpB;;IAEA;QACI,gBAAgB;QAChB,gBAAgB;IACpB;AACJ","sourcesContent":[".layout {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100vw;\n    height: 100vh;\n}\n.login {\n\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    margin: 5vh 0;\n    width: 30vw;\n\n    background-color: #289CEF;\n    color: white;\n    font-weight: bold;\n\n    border-radius: 12px;\n\n    padding: 4vh 0;\n\n}\n.login h2 {\n    font-size: 2em;\n    margin-bottom: 3vh;\n}\n\n.login-form {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    font-size: 1.4em;\n}\n.login-form > * {\n    margin-bottom: 1vh;\n}\n\n.login-form input {\n    font-size: 1em;\n    padding: .2vh;\n}\n\n.login button {\n    margin-top: 2vh;\n    font-size: 1.2em;\n    color: white;\n    background-color: #4E7B9B;\n    cursor: pointer;\n\n    padding: 1vh 1vw;\n}\n\n@media only screen and (width < 1000px) {\n    .login {\n        width: fit-content;\n        padding: 4vh 2vw;\n    }\n\n    .login button {\n        font-size: 1.4em;\n        padding: 2vh 6vw;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
