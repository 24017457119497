// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  padding: 0;
  margin: 0;
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

table {
  border-collapse: collapse;
}

button {
  font-size: 1.1em;
  font-weight: bold;
  padding: .3em .6em;

  background-color: #289cef88;
  color: black;
  border: none;
  border-radius: 12px;

  cursor: pointer;
}

button:hover {
  background-color: #289cef;
}

input {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,SAAS;;EAET;;cAEY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;;EAElB,2BAA2B;EAC3B,YAAY;EACZ,YAAY;EACZ,mBAAmB;;EAEnB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["* {\n  padding: 0;\n  margin: 0;\n  \n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n}\n\ntable {\n  border-collapse: collapse;\n}\n\nbutton {\n  font-size: 1.1em;\n  font-weight: bold;\n  padding: .3em .6em;\n\n  background-color: #289cef88;\n  color: black;\n  border: none;\n  border-radius: 12px;\n\n  cursor: pointer;\n}\n\nbutton:hover {\n  background-color: #289cef;\n}\n\ninput {\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
