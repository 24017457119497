// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    width: 100%;
    margin: 5% 0;
}

.error h1 {
    color: var(--color--black);
    font-size: 6em;
}

.error p {
    font-size: 3em;
}`, "",{"version":3,"sources":["webpack://./src/errs/Err.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,mBAAmB;;IAEnB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,0BAA0B;IAC1B,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".error {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    align-items: center;\n\n    width: 100%;\n    margin: 5% 0;\n}\n\n.error h1 {\n    color: var(--color--black);\n    font-size: 6em;\n}\n\n.error p {\n    font-size: 3em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
