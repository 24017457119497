import { Outlet } from "react-router-dom"
import './MainLayout.css'
import { logout } from "../global/API"
import { useEffect, useState } from "react"

const MainLayout = () => {
    const [hamburger, setHamburger] = useState(<></>)
    const [menuSettted, setMenuSetted] = useState(false)

    useEffect(() => {
        if (menuSettted) {
            setHamburger(
                <div className='nav-burger-content'>
                    <a href="/management/">Management</a>
                    <a href="/" onClick={() => logout()}>Odhlásit se</a>
                </div>)
        } else {
            setHamburger(<></>)
        }
    }, [menuSettted])


    return (
        <div onClick={() => {
            if (menuSettted)
                setMenuSetted(false)
            }
        }>
            <nav>
                <div>
                    <a href="/">Kotelny</a>
                </div>

                <div className='nav-links'>
                    <a href="/management/">Management</a>
                    <a href="/" onClick={() => logout()}>Odhlásit se</a>
                </div>

                <div className='nav-burger'>
                    <div className='nav-burger-menu' onClick={() => setMenuSetted(!menuSettted)}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    {hamburger}
                </div>
            </nav>
            
            <main>
                <Outlet />
            </main>

            <footer>
                <p>© Rožek Jan 2023-2025</p>
            </footer>
        </div>
    )
}

export default MainLayout