import { useEffect, useState } from 'react'
import './Boilerrooms.css'
import { ENDPOINTS, load } from '../global/API'
import { useNavigate } from 'react-router-dom'

const Boilerrooms = () => {

    const [boilerrooms, setBoilerRooms] = useState([])

    const navigate = useNavigate()

    useEffect(() => {
        load(ENDPOINTS.BOILER_ROOM)
        .then(data => setBoilerRooms(data))
        .catch(err => console.log(err))
    }, [])

    return (
        <div className='boiler_rooms'>
            <div className='boiler_rooms_header'>
                <h1>Kotelny</h1>
            </div>
            { boilerrooms.map((b,i) => <div className='boiler_rooms_item' key={i}
                onClick={() => navigate(`/boiler_room/${b.id}`)}>
                <h3>Kotelna: {b.name}</h3>
                <p>Poslední stav plynoměru: {b.lastRecord === undefined ? 'Záznam nebyl zadán' : b.lastRecord.stateOfGasometer}</p>
            </div>)}
        </div>
    )
}

export default Boilerrooms