import { useNavigate } from 'react-router-dom'
import './Management.css'

const Management = () => {
    const navigate = useNavigate()
    
    return (
        <div className='managemant'>
            <div onClick={() => navigate('/management/boiler_rooms/')}>Kotelny</div>
            <div onClick={() => navigate('/management/users/')}>Uživatelé</div>
            <div onClick={() => navigate('/management/roles/')}>Role</div>
            <div onClick={() => navigate('/management/permissions/')}>Oprávnění</div>
        </div>
    )
}

export default Management